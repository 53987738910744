<template>
  <CustomTable
    ref="CustomTable"
    class="load-calendar-table"
    title="+"
    :head="head"
    :columns="columns"
    :rows="rows"
    :visible-edit="visibleEdit"
    :has-button-remove-table="isAdmin && hasButtonRemoveTable"
    :has-button-add-column="isAdmin && hasButtonAddColumn"
    :has-button-add-row="isAdmin && hasButtonAddRow"
    :has-button-remove-row="isAdmin && hasButtonRemoveRow"
    :get-new-row="createRow"
    :update-handler="updateHandler"
    :remove-handler="removeHandler"
    @add-column="onAddColumn"
    @change-th="onTh"
    @remove-column="onRemoveColumn"
    @add-row="onAddRow"
    @change-td="checkColTd"
    @cancel-changes="onCancel"
  >
    <template #title>
      <slot name="title" />
    </template>

    <template #row-before="slotProps">
      <td
        v-if="!hideWindingMethodsCol && slotProps.rowIdx === 0"
        class="table-border-bottom-bold"
        :class="isAdmin && 'is-hover'"
        :rowspan="slotProps.data.rows.length"
        @click="isAdmin &&
          slotProps.isEdit &&
            openModalWindingMethod({ idx: slotProps.idx, data: slotProps.data })
        "
      >
        <ListSimple
          class="gap-xs"
          :list="slotProps.data.windingMethods"
          :map-fn="mapList"
        />
      </td>

      <td
        v-if="!hideDWiresCol && slotProps.rowIdx === 0"
        class="table-border-bottom-bold"
        :class="isAdmin && 'is-hover'"
        :rowspan="slotProps.data.rows.length"
        @click="isAdmin &&
          slotProps.isEdit &&
            openModalDWire({ idx: slotProps.idx, data: slotProps.data })
        "
      >
        <ListSimple
          class="gap-xs"
          :list="slotProps.data.dWires"
          :map-fn="mapRange"
        />
      </td>
    </template>

    <template #modals>
      <BaseModal
        v-if="isModal === modalFormRange.name"
        class="modal-form-range"
        title="Кол-во шт. в позиции"
        @close="closeModalFormRange"
      >
        <FormRange
          :value="modalFormRange.formData"
          input-type="number"
          pattern-key="num"
          @request="submitRange"
          @reject="closeModalFormRange"
        />
      </BaseModal>

      <ModalForm
        v-if="isModal === modalWindingMethod.name"
        v-model="modalWindingMethod.formData"
        title="Редактировать"
        @request="saveWindingMethod"
        @reject="closeModalWindingMethod"
      >
        <VSelect
          label="Способ навивки"
          class="mb-30"
          name="windingMethods"
          multiple
          :options="provideData.windingMethodsFiltered"
        />
      </ModalForm>

      <BaseModal
        v-if="isModal === modalDWire.name"
        class="modal-form-range"
        title="d проволоки"
        @close="closeModalDWire"
      >
        <FormRange
          :value="modalDWire.formData"
          @request="saveDWire"
          @reject="closeModalDWire"
        />
      </BaseModal>
      <slot name="modals" />
    </template>

    <template #sum>
      <slot name="sum" />
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from '@/shared/ui/CustomTable/CustomTable.vue'
import ListSimple from '@/shared/ui/list/ListSimple.vue'
import BaseModal from '@/components/ui/Modal/BaseModal.vue'
import ModalForm from '@/components/ui/Modal/ModalForm.vue'
import VSelect from '@/components/Form/VSelect.vue'
import FormRange from '@/shared/ui/form/FormRange/FormRange.vue'

import { mixinCustomTableProps } from '@/shared/ui/CustomTable/mixinCustomTableProps'
import { mixinCustomTableEmits } from '@/shared/ui/CustomTable/mixinCustomTableEmits'
import { mixinModal } from '@/mixins/modal/mixinModal'

import { rangeCol } from '../../model/const'

import { findById } from '@/utils/array/find'
import { generateId } from '@/utils/create'
import { mapRange } from '@/shared/lib/utils/map/to-string'
import { showAlertWarning } from '@/utils/store/alert'
import { mapGetters } from 'vuex'
import { getRangeItem } from '@/shared/ui/form/FormRange/utils'

function getRowChild (columns) {
  const rowChild = {}

  for (const col of columns) {
    if (col.key === rangeCol.key) continue

    rowChild[col.key] = ''
  }

  return rowChild
}

export default {
  name: 'LoadCalendarTable',
  components: {
    VSelect,
    ModalForm,
    FormRange,
    BaseModal,
    ListSimple,
    CustomTable
  },
  mixins: [mixinCustomTableProps, mixinCustomTableEmits, mixinModal],

  inject: ['provideData'],

  props: {
    getRowChild: {
      type: Function,
      default: getRowChild
    },
    hideWindingMethodsCol: {
      type: Boolean
    },
    hideDWiresCol: {
      type: Boolean
    }
  },

  data: () => ({
    modalFormRange: {
      name: 'range',
      formData: {
        ranges: []
      },
      payload: null
    },

    modalWindingMethod: {
      name: 'winding-method',
      formData: {
        windingMethods: []
      },
      payload: null
    },

    modalDWire: {
      name: 'd-wire',
      formData: {
        ranges: []
      },
      payload: null
    }
  }),

  computed: {
    ...mapGetters('permission', {
      isAdmin: 'isAdmin'
    })
  },

  methods: {
    mapRange,
    checkColTd (payload) {
      if (payload.col.key === rangeCol.key) {
        return this.openModalFormRange(payload)
      }

      this.onTd(payload)
    },

    submitRange (formData) {
      if (!this.rangesIsValid(formData.ranges)) return

      this.saveRange(formData)
    },

    rangesIsValid (ranges) {
      if (!ranges?.length) {
        showAlertWarning('Минимальное число диапазонов - 1')
        return false
      }

      let isValid = true
      const length = ranges.length
      const idxLast = length - 1
      let prevTo = null

      for (let i = 0; i < length; i++) {
        const { from, to } = ranges[i]
        const fromNormalize = +from
        const toNormalize = +to

        if (i !== 0) {
          if (i === idxLast) {
            if (to && fromNormalize >= toNormalize) {
              isValid = false
              this.showAlertWarningFromTo(i)
            }
          } else {
            if (fromNormalize >= toNormalize) {
              isValid = false
              this.showAlertWarningFromTo(i)
            }
          }
        }

        if (i > 0 && fromNormalize <= prevTo) {
          isValid = false
          this.showAlertWarningFrom(i)
        }

        if (length > 1 && i !== idxLast && !to) {
          isValid = false
          this.showAlertWarningTo(i)
        }

        prevTo = toNormalize
      }

      return isValid
    },

    showAlertWarningFromTo (idx) {
      showAlertWarning(
        `Значение "От" должно быть меньше, чем значение "До" в ${idx +
          1} строке`,
        10000
      )
    },
    showAlertWarningFrom (idx) {
      showAlertWarning(
        `Значение "От" в ${idx +
          1} строке должно быть больше, чем значение "До" в ${idx} строке`,
        10000
      )
    },
    showAlertWarningTo (idx) {
      showAlertWarning(
        `Значение "До" не может быть пустым в ${idx + 1} строке`,
        10000
      )
    },

    saveRange (formData) {
      const rows = []

      const { data, columns } = this.modalFormRange.payload

      for (const item of formData.ranges) {
        const range = {
          from: item.from,
          to: item.to
        }

        const dataRow = findById(data.rows, item.id)

        if (dataRow) {
          rows.push({
            ...dataRow,
            range
          })
        } else {
          rows.push({
            id: item.id,
            range,
            ...this.getRowChild(columns)
          })
        }
      }

      data.rows = rows

      this.closeModalFormRange()
    },
    openModalFormRange (payload) {
      this.modalFormRange.payload = payload

      const key = payload.col.key
      this.modalFormRange.formData.ranges = payload.data.rows.map(row => ({
        id: row.id,
        from: row[key].from,
        to: row[key].to
      }))

      this.openModal(this.modalFormRange.name)
    },
    closeModalFormRange () {
      this.closeModal()
      this.resetModalFormRange()
    },
    resetModalFormRange () {
      this.modalFormRange.formData.ranges = []
      this.modalFormRange.payload = null
    },

    saveWindingMethod (formData) {
      this.modalWindingMethod.payload.data.windingMethods = formData.windingMethods.slice()

      this.closeModalWindingMethod()
    },
    openModalWindingMethod (payload) {
      this.modalWindingMethod.payload = payload
      this.modalWindingMethod.formData.windingMethods = payload.data.windingMethods.slice()
      this.openModal(this.modalWindingMethod.name)
    },
    closeModalWindingMethod () {
      this.closeModal()
      this.resetModalWindingMethod()
    },
    resetModalWindingMethod () {
      this.modalWindingMethod.formData.windingMethods = []
      this.modalWindingMethod.payload = null
    },

    saveDWire (formData) {
      const firstRange = formData.ranges[0]
      if (formData.ranges.length > 1 || firstRange.from || firstRange.to) {
        this.modalDWire.payload.data.dWires = formData.ranges.slice()
      } else {
        this.modalDWire.payload.data.dWires = []
      }

      this.closeModalDWire()
    },
    openModalDWire (payload) {
      this.modalDWire.payload = payload
      this.modalDWire.formData.ranges = payload.data.dWires.slice()
      if (!this.modalDWire.formData.ranges.length) {
        this.modalDWire.formData.ranges.push(getRangeItem())
      }

      this.openModal(this.modalDWire.name)
    },
    closeModalDWire () {
      this.closeModal()
      this.resetModalDWire()
    },
    resetModalDWire () {
      this.modalDWire.formData.ranges = []
      this.modalDWire.payload = null
    },

    createRow (columns) {
      return {
        id: generateId(),
        windingMethods: [],
        dWires: [],
        rows: [
          {
            id: generateId(),
            range: { from: 1, to: 1 },
            ...this.getRowChild(columns)
          }
        ]
      }
    },

    mapList (value) {
      return value.name
    }
  }
}
</script>

<style lang="scss">
.load-calendar-table {
  th, td {
    min-width: initial;
    padding: var(--xs) var(--xxs);

    @media screen and (max-width: $tablet-width-s) {
      min-width: 140px;
      padding: 10px;
    }
  }

  th {
    @media screen and (max-width: $tablet-width-s) {
      text-align: center;
    }
  }

  .custom-table__td--1 {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: inherit;
  }

  .custom-table__row:last-child .table-border-bottom-bold {
    border-bottom: none;
  }
}
.modal-form-range {
  .form-grid--col-2 {
    padding-right: 45px;
    column-gap: var(--s);
  }
}
</style>
